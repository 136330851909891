@import '../variables/colors';
.malaebBg {
    background: url("../../assets/images/RedirectPage/Group\ 3.png");
    background-position: center;
    background-repeat: no-repeat;
    height: 80vh;
    background-size: cover;
    .content {
        .openApp {
            box-shadow: 0px 4px 4px 0px #00000040;
            cursor: pointer;
            a {
                color: $greenColor;
                text-decoration: none;
                font-weight: 700;
                font-size: larger;
            }
        }
        .Btns {

            .googlePlayAnc {
                width: 100%;
                height: 65px;
                background: url("../../assets/images/RedirectPage/googleplay.png");
                background-position: center;
                background-size: 98%;
                background-repeat: no-repeat;
                border-radius: 12px;
            }
            .AppStoreAnc {
                width: 100%;
                height: 65px;
                background: url("../../assets/images/RedirectPage/appstore.png");
                background-position: center;
                background-size: 98%;
                background-repeat: no-repeat;
                border-radius: 12px;

            }       
        }
    }
}
.icons {
    width: 30%;
    a >i {
        color: $greenColor;
        cursor: pointer !important;
        font-weight: 700;
        font-size: 22px;
    }
}