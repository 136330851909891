header {
  .dropdown-item {
    direction: initial;
  }
}

.form-check {
  label {
    cursor: pointer;
  }
  [dir='rtl'] & {
    padding-left: initial;
    padding-right: 1.5em;
  }
  .form-check-input {
    [dir='rtl'] & {
      float: right;
      margin-left: initial;
      margin-right: -1.5em;
    }
  }
}

.new-challenge {
  display: inline-flex;
  white-space: pre;
  [dir='rtl'] & {
    flex-direction: row-reverse;
  }
}

.malaeb-spaces {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 3rem;
  h5 {
    // background: #85c240;
    background: #ccc;
    color: #fff;
    padding: 0.3rem 1rem;
    margin-inline: 1rem;
  }
  .malaeb-class {
    background: #85c240 !important;
  }
}

.cardList {
  .card-img-top {
    [dir='rtl'] & {
      border-radius: 0 20px 0 20px;
    }
  }
}

.topStaduims {
  .card-body {
    > h5 {
      display: flex;
      justify-content: space-between;
    }
  }
}