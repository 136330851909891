.faqs-section {
  header {
    background: url('../../assets/images/bgImage.png');
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 21rem;
  }
  .faqs-row {
    margin-bottom: 1rem;
  }
  .card {
    border: none;
  }
  .card-body {
    background-color: #EAECEE;
  }
  .faqs-collapse {
    display: flex;
    padding: 1rem 0.5rem;
    cursor: pointer;
    .icon-items-section {
      border: 1px solid;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      text-align: center;
      justify-content: center;
    }
  }
}
