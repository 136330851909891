.formSection {
    background-color: #F6F6F2;

    .first-div {
        h3 {
            color: #87BE44;
            font-weight: 700;
            font-size: 48px;
        }

        .info {
            i {
                color: #87BE44;
            }
        }
    }

    .second-div {
        background-color: #fff;

        .formContainer {
            padding: 30px 50px 40px 30px;

            .option {
                color: #4F504E;
                border: 2px solid #5c5d5b;
                border-radius: 8px;
                cursor: pointer;
            }

            .selectedOption {
                background-color: #87BE44;
                color: #fff;
                border: 2px solid #87BE44;
                border-radius: 8px;
            }


            .textInp {
                outline: 0;
                border-width: 0 0 2px;
                border-color: #4F504E;
                color: #4F504E;
                padding-bottom: 8px;
            }

            .textInp:focus {
                border-color: #87BE44 !important;
                outline: 0
            }

            .textError {
                outline: 0;
                border-width: 0 0 2px;
                border-color: red;
                color: #4F504E;
            }

            .textError:focus {
                border-color: red !important;
                outline: 0
            }

            .btn-default {
                padding: 15px 44px;
                border-radius: 8px;

            }

            .textInpwithImg {

                background-image: url("../../assets/images/BH.svg");
                background-repeat: no-repeat;
                background-size: auto;
                text-align: right;
                padding-right: 8px;
                padding-bottom: 8px;
                background-size: 33%;
                background-position-y: -2px;
                width: 18%;
                background-position-x: 5px;
                @media only screen and (max-width:900px) {
                    background-position-y: 6px;
                    background-position-x: -2px;
                    font-size: 0.7rem;
                    padding-right: 4px;
                }
            }

            .afterInpEn {
                display: inline-block;
                height: 30px;
                background-size: 27%;
                width: 3px;
                background-color: #4F504E;
                margin-bottom: 5px;
                left: 15%;
                bottom: 2px;
            }

            .afterInpAr {
                display: inline-block;
                height: 30px;
                width: 3px;
                background-color: #4F504E;
                margin-bottom: 5px;
                right: 15%;
                bottom: 2px;
            }

            .phoneNumberInp {
                padding-left: 8px;
                padding-bottom: 8px;

            }
        }


    }

}