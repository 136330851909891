.contact-us {
  header {
    background: url('../../assets/images/contact.png');
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 21rem;
  }
  .title {
    font-weight: 600;
    color: black;
  }
  .sub-title {
    max-width: 210px;
    color: black;
    font-weight: 600;
  }
  .contact-details {
    li {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
      p,
      a {
        margin: 0;
        padding-inline: 0.5rem;
        color: black;
        text-decoration: none;
      }
      a {
        &:hover {
          color: #85c240;
          transition: all 0.2s ease-in-out;
        }
      }
      i {
        color: black;
      }
    }
  }
  .phone-number {
    [dir='rtl'] & {
      direction: ltr;
    }
  }
}
