.me-auto {
  [dir='rtl'] & {
    margin-left: auto;
    margin-right: initial !important;
  }
}

.font-weight-bold {
  font-weight: 600;
}



.input-group-text {
  [dir='rtl'] & {
    border-top-right-radius: 0.3rem !important;
    border-bottom-right-radius: 0.3rem !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.input-group {
  input[type='number'] {
    [dir='rtl'] & {
      border-top-left-radius: 0.3rem !important;
      border-bottom-left-radius: 0.3rem !important;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.editNavbar {
  .dropdown {
    background: #f8f9fa;
  }

  .dropdown-toggle {
    &::after {
      margin-left: 0.5rem;
      margin-right: 0.5rem;

      [dir='rtl'] & {
        float: left;
      }
    }
  }
}

.lang-dropdown {
  >button {
    text-align: left;
    width: 100%;

    [dir='rtl'] & {
      text-align: right;
    }
  }
}

.img-lang {
  margin: 0 0.2rem;
}

//Toggle checkbox
.switch-checkbox {
  display: inline-block;
  height: 34px;
  position: relative;
  width: 60px;
}

.switch-checkbox input {
  display: none;
}

.slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

.slider:before {
  background-color: #fff;
  bottom: 4px;
  content: '';
  height: 26px;
  left: 4px;
  position: absolute;
  transition: 0.4s;
  width: 26px;
}

input:checked+.slider {
  background-color: #85c240;
}

input:checked+.slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

//Loader
.loader {
  background-color: #000000a3 !important;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}

//Media Screen
@media screen and (max-width: 767px) {
  .bgImage {
    [dir='rtl'] & {
      margin-right: initial;
      margin-left: 1rem;
    }
  }
}


.notification-parent {
  z-index: 99999999999;
}

.text-underline {
  text-decoration: underline;
}

.cursor-disabled {
  cursor: no-drop !important;
}

.font-small {
  font-size: medium !important;
}

.btn-default {
  background-color: #98c11d;
  border-color: #98c11d;
  color: #fff;
}

.cursor-pointer {
  cursor: pointer !important;
}

.grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 4px !important;
}

.grecaptcha-badge:hover {
  width: 256px !important;
}