.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  transition: all 1s ease;
  animation: smoothScroll 1s forwards;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.075);
  z-index: 99;
  background: white;

}

@keyframes smoothScroll {
  0% {
    transform: translateY(-142px);
  }

  100% {
    transform: translateY(0px);
  }
}
