.about-section {
  color: black;
  header {
    background: url('../../assets/images/bgImage.png');
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 21rem;
  }
  .about-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3rem 0;
    @media (max-width: 575px) {
      flex-wrap: wrap-reverse;
      justify-content: center;
    }
    .about-img {
      @media (max-width: 575px) {
        margin-bottom: 1rem;
      }
    }
    .about-title {
      margin-bottom: 1.5rem;
      font-weight: 600;
    }
    .about-ul {
      padding-inline: 1.3rem;
      li {
        font-weight: 600;
        &::marker {
          color: #85c240;
        }
      }
    }
  }
  .leading-sports {
    margin: 2rem 1rem;
    .leading-head {
      max-width: 23%;
      margin: auto;
      text-align: center;
      color: black;
      margin-bottom: 2rem;
      font-size: 24px;
      @media(max-width: 575px) {
        max-width: 100%;
      }
    }
    .quote {
      > p {
        background: #f8f9fa;
        padding: 1rem;
        border-radius: 20px;
        font-style: italic;
        margin-bottom: 0%;
        font-weight: 600;
      }
      .quote-name {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        margin-inline: 1rem;
        > p {
          margin: 0;
          color: #8abd24;
          font-size: 22px;
        }
        > img {
          padding-inline: 1rem;
        }
      }
    }
  }

  .mission-about {
    margin-bottom: 4rem;
    .about-title {
      margin-bottom: 1rem;
    }
    li {
      margin-bottom: 1rem;
      font-size: 18px;
    }
  }

  .sustainable-company {
    margin-bottom: 4rem;
    .about-title {
      margin-bottom: 1rem;
    }
    li {
      font-size: 18px;
      margin-bottom: 1.5rem;
      h6 {
        margin: 0;
      }
    }
  }

  .brand-personality {
    margin-bottom: 4rem;
    .about-title {
      margin-bottom: 1rem;
    }
  }

  .the-team {
    p {
      font-size: 18px;
    }
    ul {
      margin-top: 3rem;
    }
    li {
      margin: 1.5rem 0;
    }
    .team-card {
      text-align: center;
      .img-box {
        height: 150px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      h5 {
        margin: 0;
      }
      p {
        font-size: 14px;
      }
    }
  }

}
