.events {
    font-family: 'Outfit', sans-serif;
    font-family: 'Roboto', sans-serif;

    @media only screen and (max-width: 900px) {
        overflow-x: hidden;
    }

    .event-title {
        position: relative;

        h2 {
            font-weight: 700;
            font-size: 3rem;
            z-index: 9;
        }

        .locationImg {
            position: absolute;
            top: 50%;
            right: 6%;
            z-index: 1;

            @media only screen and (max-width: 900px) {
                position: relative;
                margin: auto;
                right: 0%;
                width: 50% !important;
            }
        }
    }

    .contactus-div {
        font-weight: 700;
        font-size: 28px;
        position: relative;



        .btnContact {
            z-index: 9 !important;

            a {
                color: #fff;
                text-decoration: none;
            }
        }

        .backgroundImgDiv {
            position: absolute;
            left: -50px;
            z-index: 1 !important;
            width: 40%;

        }
    }

    .ourServices-div {
        position: relative;

        h4 {
            font-weight: 600;
            font-size: 30px;
            display: block;

        }

        .after {
            &::after {
                width: 10%;
                content: "";
                height: 4px;
                background-color: #87BE44;
                display: inline-block;
            }
        }



        .servicesImges {
            background-color: #fff;
            z-index: 9;
            position: relative;

            .servicesImgDiv {
                height: 222px;
                position: relative;

                @media only screen and (max-width: 768pxx) {
                    padding-bottom: 10px;
                }

                img {
                    width: 80% !important;
                }

                .paragraph {
                    position: absolute;
                    text-align: center;
                    top: 80%;
                    font-weight: 600;
                    margin-bottom: 0;

                    @media only screen and (max-width: 768px) {
                        top: 90%;
                    }
                }
            }
        }

        .backgroundImgDivtwo {
            position: absolute;
            width: 33%;
            right: -173px;
            bottom: -65px;
            z-index: 1 !important;

        }
    }



    .ourClents-div {
        h4 {
            font-weight: 600;
            font-size: 30px;
            display: block;

        }

        .after {
            &::after {
                width: 20%;
                content: "";
                height: 4px;
                background-color: #87BE44;
                display: inline-block;
            }
        }
    }
}

.peopleImgDiv {
    height: 450px;
    background-image: url("../../assets/images/eventPage/image-28-2.webp");
    background-size: cover;
    position: relative;

    @media only screen and (max-width: 900px) {
        margin-bottom: 350px;
    }

    .divOfClientsOpinion {
        position: absolute;
        top: -60px;

        .clientOpinion {
            width: 340px;
            height: 252px;
            background-color: #fff;
            position: relative;
            box-shadow: 0px 10px 40px rgba(136, 190, 70, 0.4);

            .quotation {
                position: absolute;
                color: #87BE448A;
                font-weight: 700;
                top: 10px;
                font-size: 60px;
                left: 5px;
            }

            .paragrapgh {
                color: #9FB880;
                font-size: 1rem;
            }
        }
    }
}