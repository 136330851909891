html {
  --right: right;
  --left: left;
  --dir: ltr;
}

// Pages
@import './pages/home';
@import './pages/about';
@import './pages/contact';
@import './pages/events';
@import './pages/createEvent';
@import './pages/faqs';
@import './pages/terms';
@import './pages/header';
@import './pages/Error404';
@import './pages/redirectPage';
@import './pages/typeForm';
@import './pages/liveScoring';
@import './base/all';

html[dir='rtl'] {
  --dir: rtl;
  --left: right;
  --right: left;

  @font-face {
    src: url('../assets/webfonts/Cairo-Regular.ttf');
    font-family: cairo;
  }

  @font-face {
    font-family: myFont !important;
    src: url("../assets/webfonts/Mark\ Simonson\ -\ Proxima\ Nova\ Semibold.otf");
  }

  body {
    font-family: 'Cairo', sans-serif;
  }


}